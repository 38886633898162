import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import UserPool from './UserPool';
import { TextField, Button, Container, Box, Typography, Alert, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { styled } from '@mui/material/styles';

const StyledPhoneInput = styled(PhoneInput)({
  '& .PhoneInputInput': {
    backgroundColor: 'inherit', // Fond hérité pour l'input interne
    border: '1px solid rgba(0, 0, 0, 0.23)', // Bord transparent pour l'input interne
    outline: 'none', // Suppression du contour
    width: '100%', // Largeur complète de l'input
    height: '55px',
    marginTop: '15px',
    marginBottom: '10px',
    marginLef: '10px',
  }

});


function Login({ onLogin }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState('');
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [medicalRole, setMedicalRole] = useState('General Practitioner');
  const [countryCode, setCountryCode] = useState('US'); // Par défaut sur US
  const navigate = useNavigate();

  // Logique pour récupérer le pays par défaut via géolocalisation
  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then((res) => res.json())
      .then((data) => {
        if (data && data.country_code) {
          setCountryCode(data.country_code); // Récupère le code pays pour définir le code par défaut
        }
      })
      .catch(() => {
        console.log('Erreur lors de la récupération de la géolocalisation');
      });
  }, []);

  const toggleRegister = () => {
    setIsRegistering(!isRegistering);
    setError('');
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    const user = new CognitoUser({ Username: email, Pool: UserPool });
    const authDetails = new AuthenticationDetails({ Username: email, Password: password });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        const idToken = result.getIdToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();

        document.cookie = `jwt=${idToken}; path=/; SameSite=Strict; Secure`;
        document.cookie = `refreshToken=${refreshToken}; path=/; SameSite=Strict; Secure`;

        onLogin();
        navigate('/dashboard');
      },
      onFailure: (err) => setError(err.message || JSON.stringify(err)),
    });
  };

  const handleSignUp = (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError(t('login.passwordsDontMatch'));
      return;
    }

    const attributeList = [
      new CognitoUserAttribute({ Name: 'given_name', Value: givenName }),
      new CognitoUserAttribute({ Name: 'family_name', Value: familyName }),
      new CognitoUserAttribute({ Name: 'phone_number', Value: `+${phoneNumber}` }),
      new CognitoUserAttribute({ Name: 'address', Value: address }),
      new CognitoUserAttribute({ Name: 'custom:medicalRole', Value: medicalRole }),
      new CognitoUserAttribute({ Name: 'email', Value: email }),
    ];

    UserPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        setError(err.message || JSON.stringify(err));
        return;
      }
      setIsRegistering(false);
    });
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          {isRegistering ? t('login.register') : t('login.login')}
        </Typography>
        {error && <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>{error}</Alert>}

        {isRegistering ? (
          <Box component="form" onSubmit={handleSignUp} sx={{ mt: 1 }}>
            <TextField variant="outlined" margin="normal" required fullWidth label={t('login.firstName')} value={givenName} onChange={(e) => setGivenName(e.target.value)} />
            <TextField variant="outlined" margin="normal" required fullWidth label={t('login.lastName')} value={familyName} onChange={(e) => setFamilyName(e.target.value)} />

            {/* Champ de téléphone avec react-phone-number-input */}
            <StyledPhoneInput
              placeholder={t('login.phone')}
              value={phoneNumber}
              onChange={setPhoneNumber}
              defaultCountry={countryCode}
              required
              international
            />

            <TextField variant="outlined" margin="normal" required fullWidth label={t('login.address')} value={address} onChange={(e) => setAddress(e.target.value)} />
            <FormControl fullWidth margin="normal">
              <InputLabel>{t('login.medicalRole')}</InputLabel>
              <Select value={medicalRole} onChange={(e) => setMedicalRole(e.target.value)} label={t('login.medicalRole')}>
                <MenuItem value="Nurse">{t('login.nurse')}</MenuItem>
                <MenuItem value="General Practitioner">{t('login.generalPractitioner')}</MenuItem>
                <MenuItem value="Cardiologist">{t('login.cardiologist')}</MenuItem>
                <MenuItem value="Electrophysiologist">{t('login.electrophysiologist')}</MenuItem>
                <MenuItem value="Other Specialist">{t('login.otherSpecialist')}</MenuItem>
              </Select>
            </FormControl>
            <TextField variant="outlined" margin="normal" required fullWidth label={t('login.email')} value={email} onChange={(e) => setEmail(e.target.value)} />
            <TextField variant="outlined" margin="normal" required fullWidth label={t('login.password')} type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <TextField variant="outlined" margin="normal" required fullWidth label={t('login.confirmPassword')} type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
            <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>{t('login.register')}</Button>
          </Box>
        ) : (
          <Box component="form" onSubmit={handleSignIn} sx={{ mt: 1 }}>
            <TextField variant="outlined" margin="normal" required fullWidth id="email" label={t('login.email')} name="email" autoComplete="email" autoFocus value={email} onChange={(e) => setEmail(e.target.value)} />
            <TextField variant="outlined" margin="normal" required fullWidth name="password" label={t('login.password')} type="password" id="password" autoComplete="current-password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>{t('login.login')}</Button>
          </Box>
        )}
        <Button variant="text" onClick={toggleRegister} sx={{ mt: 2 }}>
          {isRegistering ? t('login.alreadyHaveAccount') : t('login.dontHaveAccount')}
        </Button>
      </Box>
    </Container>
  );
}

export default Login;

