import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag";
import { AppBar, Toolbar, IconButton, Container, Box, Menu, MenuItem, Avatar, Typography } from '@mui/material';
import { ExpandMore, AccountCircle, Login, Logout } from '@mui/icons-material'; // MUI Icons for log in/out and user
import logo from './assets/images/logo_transparent.png';

function Layout({ isAuthenticated, onLogout, children }) {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <AppBar position="static" color="transparent" elevation={0}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
              <img src={logo} alt="Emcardia Logo" style={{ width: '150px' }} />
            </Link>
            <Box sx={{ flexGrow: 1 }} />
            <Box display="flex" alignItems="center">
              {/* Language Selector with modern flags */}
              <IconButton
                onClick={() => changeLanguage('en')}
                color="inherit"
                sx={{ '&:focus': { outline: 'none' } }} // Remove blue outline on focus
              >
                <ReactCountryFlag 
                  countryCode="US" 
                  svg 
                  style={{ width: '32px', height: '32px', borderRadius: '50%' }} 
                  title="Switch to English"
                />
              </IconButton>
              <IconButton
                onClick={() => changeLanguage('fr')}
                color="inherit"
                sx={{ '&:focus': { outline: 'none' } }} // Remove blue outline on focus
              >
                <ReactCountryFlag 
                  countryCode="FR" 
                  svg 
                  style={{ width: '32px', height: '32px', borderRadius: '50%' }} 
                  title="Passer en Français"
                />
              </IconButton>

              {isAuthenticated && (
                <Box ml={2}>
                  <IconButton onClick={handleMenuOpen} color="inherit" sx={{ '&:focus': { outline: 'none' } }}>
                    <Avatar alt="User Icon" sx={{ bgcolor: 'primary.main' }}>
                      <AccountCircle />
                    </Avatar>
                    <ExpandMore />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  >
                    <MenuItem component={Link} to="/dashboard" onClick={handleMenuClose}>
                      {i18n.t('Dashboard')}
                    </MenuItem>
                    <MenuItem component={Link} to="/patients" onClick={handleMenuClose}>
                      {i18n.t('Patients')}
                    </MenuItem>
                    <MenuItem component={Link} to="/ecg" onClick={handleMenuClose}>
                      {i18n.t('Electocardiograms')}
                    </MenuItem>
                  </Menu>
                </Box>
              )}

              {isAuthenticated ? (
                <IconButton onClick={onLogout} color="inherit" sx={{ '&:focus': { outline: 'none' } }}>
                  <Logout sx={{ fontSize: '32px' }} /> {/* Updated log out icon */}
                </IconButton>
              ) : (
                <IconButton component={Link} to="/login" color="inherit" sx={{ '&:focus': { outline: 'none' } }}>
                  <Login sx={{ fontSize: '32px' }} /> {/* Updated log in icon */}
                </IconButton>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Box component="main" flexGrow={1} mt={8}>
        {children}
      </Box>

      <Box component="footer" py={3} mt="auto" bgcolor="#333333">
        <Container maxWidth="lg">
          <Typography variant="body2" color="white" align="center">
            © 2024 Emcardia. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}

export default Layout;

