import React from 'react';
import { useTranslation } from 'react-i18next'; // Import du hook useTranslation
import heroImage from './assets/images/image.png';
import './assets/css/styles.css';

function Home() {
  const { t } = useTranslation(); // Utilisation du hook pour accéder aux traductions

  const carouselItems = t('home.carousel', { returnObjects: true }); // Récupérer les éléments du carrousel à partir de la traduction

  return (
    <div>
      {/* Hero Section */}
      <section className="hero d-flex justify-content-center align-items-center mb-5">
        <div className="hero-content text-center d-flex align-items-center">
          <img src={heroImage} alt="Hero" className="hero-image mr-3" />
          <div>
            <h2 className="text-black">{t('home.heroTitle')}</h2> {/* Titre traduit */}
            <p className="text-black">{t('home.heroSubtitle')}</p> {/* Sous-titre traduit */}
          </div>
        </div>
      </section>

      {/* Carousel Section */}
      <section className="content py-5">
        <div className="container">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            {/* Indicateurs */}
            <ol className="carousel-indicators">
              {carouselItems.map((item, index) => (
                <li
                  key={index}
                  data-target="#carouselExampleIndicators"
                  data-slide-to={index}
                  className={index === 0 ? 'active' : ''}
                ></li>
              ))}
            </ol>

            {/* Éléments du carrousel */}
            <div className="carousel-inner">
              {carouselItems.map((item, index) => (
                <div
                  key={index}
                  className={`carousel-item ${index === 0 ? 'active' : ''}`}
                >
                  <div className="row justify-content-center align-items-center icon-card">
                    <div className="col-md-3 text-center d-flex align-items-center justify-content-center">
                      <i className={item.icon}></i> {/* Icône */}
                    </div>
                    <div className="col-md-9">
                      <h3 className="problem">{item.problem}</h3> {/* Problème traduit */}
                      <p className="solution">{item.solution}</p> {/* Solution traduite */}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Contrôles du carrousel */}
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;

