import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importer les fichiers de traduction
import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';

// La configuration des ressources (fichiers de traduction)
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(LanguageDetector) // Utilise le détecteur de langue basé sur le navigateur
  .use(initReactI18next) // Initialise react-i18next
  .init({
    resources, // Les ressources de traduction
    fallbackLng: 'en', // Langue par défaut
    debug: true,
    interpolation: {
      escapeValue: false, // React gère déjà l'échappement des valeurs
    },
  });

export default i18n;

