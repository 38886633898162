import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,

} from '@mui/material';


const API_URL = 'https://api.emcardia.com/patients';
const API_KEY = 'QZtBbIjzTQ7zVc3gKQxYE9EOy3Z99PWE10lYtgY5';


const PatientsList = ({ patients, setFormData, setShowForm }) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('patientCode');
  const [filterDate, setFilterDate] = useState('');

  // Fonction pour trier les colonnes
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Fonction pour filtrer par date de naissance
  const handleFilterDateChange = (event) => {
    setFilterDate(event.target.value);
  };

  // Fonction pour trier les patients
  const sortPatients = (patients) => {
    return patients.sort((a, b) => {
      if (order === 'asc') {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      }
      return a[orderBy] > b[orderBy] ? -1 : 1;
    });
  };

  // Fonction pour filtrer les patients par date de naissance
  const filterPatients = (patients) => {
    if (!filterDate) return patients;
    return patients.filter((patient) =>
      patient.dateOfBirth.startsWith(filterDate)
    );
  };

  // Application du tri et du filtre
  const sortedAndFilteredPatients = filterPatients(sortPatients([...patients]));

  return (
    <Paper style={{ padding: '16px', marginBottom: '24px' }}>
      {/* Filtre sur la date de naissance */}
      <TextField
        label={t('patients.filterByDateOfBirth')}
        type="date"
        value={filterDate}
        onChange={handleFilterDateChange}
        InputLabelProps={{
          shrink: true,
        }}
        style={{ marginBottom: '16px', width: '100%' }}
      />

      {/* Tableau des patients */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'patientCode'}
                direction={orderBy === 'patientCode' ? order : 'asc'}
                onClick={() => handleSort('patientCode')}
              >
                {t('patients.patientCode')}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'accessCode'}
                direction={orderBy === 'accessCode' ? order : 'asc'}
                onClick={() => handleSort('accessCode')}
              >
                {t('patients.accessCode')}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'dateOfBirth'}
                direction={orderBy === 'dateOfBirth' ? order : 'asc'}
                onClick={() => handleSort('dateOfBirth')}
              >
                {t('patients.dateOfBirth')}
              </TableSortLabel>
            </TableCell>
            <TableCell>{t('patients.viewMore')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedAndFilteredPatients.map((patient, index) => (
            <TableRow key={index}>
              <TableCell>{patient.patientCode}</TableCell>
              <TableCell>{patient.accessCode}</TableCell>
              <TableCell>{patient.dateOfBirth}</TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setFormData(patient);
                    setShowForm(true);
                  }}
                >
                  {t('patients.viewDetails')}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};


function Patient() {
  const { t } = useTranslation();

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return null;
  };

  const initialFormData = {
    gender: '',
    dateOfBirth: '',
    symptoms: [],
    prescriptionDrugs: [],
    arrythmias: 'no',
    symptomPersistence: '',
    crisisDuration: '',
    crisisDurationUnit: '',
    triggers: [],
    maneuvers: [],
    maneuverTiming: '',
    exams: {
      echocardiography: false,
      electrophysiology: false,
      stressTest: false,
      otherExam: false,
      echoConclusion: '',
      electrophysiologyConclusion: '',
      stressTestConclusion: '',
      otherExamConclusion: '',
    },
  };

  const [formData, setFormData] = useState(initialFormData);

  const resetForm = () => {
    setFormData(initialFormData);
  };

  const [patients, setPatients] = useState([]);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const token = getCookie('jwt');
    axios
      .get(API_URL, {
        headers: {
          'x-api-key': API_KEY,
          'Authorization': `Bearer ${token}`
        },
      })
      .then((response) => {
        setPatients(response.data);
      })
      .catch(() => {
        alert('Failed to fetch patients. Please try again later.');
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSymptomChange = (e) => {
    const { value, checked } = e.target;

    let updatedSymptoms;

    // Si "Aucun" est coché, on désélectionne tout le reste
    if (value === t('patients.symptomList.none') && checked) {
      updatedSymptoms = [t('patients.symptomList.none')];
    }
    // Si un autre symptôme est coché alors que "Aucun" est sélectionné, on retire "Aucun"
    else if (value !== t('patients.symptomList.none') && checked) {
      updatedSymptoms = [...formData.symptoms.filter(symptom => symptom !== t('patients.symptomList.none')), value];
    }
    // Si un autre symptôme est décoché
    else {
      updatedSymptoms = formData.symptoms.filter((symptom) => symptom !== value);
    }

    // Mettre à jour le formData avec la nouvelle liste de symptômes
    setFormData({ ...formData, symptoms: updatedSymptoms });
  };

  const handlePrescriptionChange = (e) => {
    const { value, checked } = e.target;

    let updatedPrescriptions;

    // Si "Aucun" est coché, on désélectionne tout le reste
    if (value === t('patients.prescriptionList.none') && checked) {
      updatedPrescriptions = [t('patients.prescriptionList.none')];
    }
    else if (value !== t('patients.prescriptionList.none') && checked) {
      updatedPrescriptions = [...formData.prescriptionDrugs.filter(prescription => prescription !== t('patients.prescriptionList.none')), value];
    }
    else {
      updatedPrescriptions = formData.prescriptionDrugs.filter((prescription) => prescription !== value);
    }

    // Mettre à jour le formData avec la nouvelle liste de prescriptions
    setFormData({ ...formData, prescriptionDrugs: updatedPrescriptions });
  };

  const handleTriggerChange = (e) => {
    const { value, checked } = e.target;
    const updatedTriggers = checked
      ? [...formData.triggers, value]
      : formData.triggers.filter((trigger) => trigger !== value);
    setFormData({ ...formData, triggers: updatedTriggers });
  };

  const handleManeuverChange = (e) => {
    const { value, checked } = e.target;
    const updatedManeuvers = checked
      ? [...formData.maneuvers, value]
      : formData.maneuvers.filter((maneuver) => maneuver !== value);
    setFormData({ ...formData, maneuvers: updatedManeuvers });
  };

  const handleExamChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      exams: {
        ...formData.exams,
        [name]: checked,
      },
    });
  };

  const handleExamConclusionChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      exams: {
        ...formData.exams,
        [name]: value,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { ...formData };

    const token = getCookie('jwt');
    const isEditMode = !!data.patientCode; // Si le patient a un patientCode, c'est une mise à jour

    if (isEditMode) {
      // Effectuer un PUT pour mettre à jour le patient existant
      axios
        .put(`${API_URL}`, data, {
          headers: {
            'x-api-key': API_KEY,
            'Authorization': `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            // Mettre à jour la liste des patients après la mise à jour
            axios
              .get(API_URL, {
                headers: {
                  'x-api-key': API_KEY,
                  'Authorization': `Bearer ${token}`,
                },
              })
              .then((response) => {
                setPatients(response.data); // Mettre à jour la liste des patients
              })
              .catch(() => {
                alert('Failed to refresh patients list. Please try again later.');
              });
            setShowForm(false); // Fermer le formulaire
          }
        })
        .catch(() => {
          alert('Error updating patient.');
        });
    } else {
      // Effectuer un POST pour créer un nouveau patient
      axios
        .post(API_URL, data, {
          headers: {
            'x-api-key': API_KEY,
            'Authorization': `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 201) {
            // Mettre à jour la liste des patients après la création
            axios
              .get(API_URL, {
                headers: {
                  'x-api-key': API_KEY,
                  'Authorization': `Bearer ${token}`,
                },
              })
              .then((response) => {
                setPatients(response.data); // Mettre à jour la liste des patients
              })
              .catch(() => {
                alert('Failed to refresh patients list. Please try again later.');
              });
            setShowForm(false); // Fermer le formulaire
          }
        })
        .catch(() => {
          alert('Error creating patient.');
        });
    }
  };


  return (
    <div className="patient-container" style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '800px', width: '100%' }}>
        {/* Bouton pour afficher/masquer le formulaire */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (showForm) {
              resetForm();
            }
            setShowForm(!showForm);
          }}
          style={{ marginBottom: '20px' }}
        >
          {showForm ? t('patients.cancel') : t('patients.addNewPatient')}
        </Button>

        {/* Formulaire de création de patient */}
        {showForm && (
          <Paper style={{ padding: '16px', marginBottom: '24px' }}>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label={t('patients.dateOfBirth')}
                  type="date"
                  name="dateOfBirth"
                  value={formData.dateOfBirth ? formData.dateOfBirth : ''}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>{t('patients.sex')}</InputLabel>
                <Select
                  label={t('patients.sex')}
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <MenuItem value="male">{t('patients.male')}</MenuItem>
                  <MenuItem value="female">{t('patients.female')}</MenuItem>
                </Select>
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <Typography variant="subtitle1">{t('patients.symptoms')}</Typography>
                <FormGroup>
                  {['none', 'chestPain', 'dyspnea', 'palpitations', 'syncope', 'other'].map((symptomKey, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          value={t(`patients.symptomList.${symptomKey}`)}
                          onChange={handleSymptomChange}
                          checked={formData.symptoms.includes(t(`patients.symptomList.${symptomKey}`))}
                          disabled={
                            formData.symptoms.includes(t('patients.symptomList.none')) &&
                            symptomKey !== 'none'
                          }
                        />
                      }
                      label={t(`patients.symptomList.${symptomKey}`)}
                    />
                  ))}
                </FormGroup>
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <Typography variant="subtitle1">{t('patients.prescriptions')}</Typography>
                <FormGroup>
                  {['none', 'antiarrhythmics', 'calciumChannelBlockers', 'betaBlockers', 'sodiumChannelBlockers', 'other'].map((prescriptionKey, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          value={t(`patients.prescriptionList.${prescriptionKey}`)}
                          onChange={handlePrescriptionChange}
                          checked={formData.prescriptionDrugs.includes(t(`patients.prescriptionList.${prescriptionKey}`))}
                          disabled={
                            formData.prescriptionDrugs.includes(t('patients.prescriptionList.none')) &&
                            prescriptionKey !== 'none'
                          }
                        />
                      }
                      label={t(`patients.prescriptionList.${prescriptionKey}`)}
                    />
                  ))}
                </FormGroup>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>{t('patients.arrythmias')}</InputLabel>
                <Select
                  label={t('patients.arrythmias')}
                  name="arrythmias"
                  value={formData.arrythmias}
                  onChange={handleInputChange}
                >
                  <MenuItem value="yes">{t('patients.yes')}</MenuItem>
                  <MenuItem value="no">{t('patients.no')}</MenuItem>
                </Select>
              </FormControl>

              {formData.arrythmias !== 'no' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>{t('patients.arrhythmiasPersistence')}</InputLabel>
                  <Select
                    label={t('patients.arrhythmiasPersistence')}
                    name="symptomPersistence"
                    value={formData.symptomPersistence}
                    onChange={handleInputChange}
                  >

                    <MenuItem value="paroxysmal">{t('patients.paroxysmal')}</MenuItem>
                    <MenuItem value="persistent">{t('patients.persistent')}</MenuItem>
                  </Select>
                </FormControl>

              )}
              {formData.arrythmias !== 'no' && formData.symptomPersistence !== 'persistent' && (
                <FormControl margin="normal">
                  <Typography variant="subtitle1">{t('patients.durationOfCrises')}</Typography>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      type="number"
                      name="crisisDuration"
                      value={formData.crisisDuration}
                      onChange={handleInputChange}
                      style={{ marginRight: '8px' }}
                    />
                    <FormControl>
                      <Select
                        name="crisisDurationUnit"
                        value={formData.crisisDurationUnit}
                        onChange={handleInputChange}
                      >
                        <MenuItem value="min">{t('patients.minutes')}</MenuItem>
                        <MenuItem value="hours">{t('patients.hours')}</MenuItem>
                        <MenuItem value="days">{t('patients.days')}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </FormControl>
              )}

              {formData.arrythmias !== 'no' && (
                <FormControl component="fieldset" margin="normal">
                  <Typography variant="subtitle1">{t('patients.triggers')}</Typography>
                  <FormGroup>
                    {['physicalActivity', 'stress', 'excitants', 'fever', 'positionChange'].map((triggerKey, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            value={t(`patients.triggerList.${triggerKey}`)}
                            onChange={handleTriggerChange}
                            checked={formData.triggers.includes(t(`patients.triggerList.${triggerKey}`))}
                          />
                        }
                        label={t(`patients.triggerList.${triggerKey}`)}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              )}

              {formData.arrythmias !== 'no' && (
                <FormControl component="fieldset" margin="normal">
                  <Typography variant="subtitle1">{t('patients.maneuversToStopCrises')}</Typography>
                  <FormGroup>
                    {['respiration', 'relaxation', 'lyingDown', 'vagalManeuver', 'none'].map((maneuverKey, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            value={t(`patients.maneuverList.${maneuverKey}`)}
                            onChange={handleManeuverChange}
                            checked={formData.maneuvers.includes(t(`patients.maneuverList.${maneuverKey}`))}
                            disabled={
                              formData.maneuvers.includes(t('patients.maneuverList.none')) &&
                              maneuverKey !== 'none'
                            }
                          />
                        }
                        label={t(`patients.maneuverList.${maneuverKey}`)}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              )}
              {formData.maneuvers.length > 0 &&
                !formData.maneuvers.includes('none') && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>{t('patients.whenManeuverEffective')}</InputLabel>
                    <Select
                      label={t('patients.whenManeuverEffective')}
                      name="maneuverTiming"
                      value={formData.maneuverTiming}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="start">{t('patients.startOfCrisis')}</MenuItem>
                      <MenuItem value="anytime">{t('patients.anytime')}</MenuItem>
                    </Select>
                  </FormControl>
                )}


              <FormControl component="fieldset" margin="normal">
                <Typography variant="subtitle1">{t('patients.complementaryExams')}</Typography>
                <FormGroup>
                  {['echocardiography', 'electrophysiology', 'stressTest', 'otherExam'].map((exam, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          name={exam}
                          checked={formData.exams[exam]}
                          onChange={handleExamChange}
                        />
                      }
                      label={t(`patients.exams.${exam}`)}
                    />
                  ))}
                </FormGroup>
              </FormControl>

              {['echocardiography', 'electrophysiology', 'stressTest', 'otherExam'].map((exam, index) => (
                formData.exams[exam] && (
                  <FormControl fullWidth margin="normal" key={index}>
                    <TextField
                      label={t(`patients.examConclusion.${exam}`)}
                      name={`${exam}Conclusion`}
                      value={formData.exams[`${exam}Conclusion`]}
                      multiline
                      rows={3}
                      onChange={handleExamConclusionChange}
                    />
                  </FormControl>
                )
              ))}

              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: '20px', alignSelf: 'flex-end' }}
              >
                {t('patients.save')}
              </Button>
            </form>
          </Paper>
        )}

        {/* Liste des patients */}
        <PatientsList patients={patients} setFormData={setFormData} setShowForm={setShowForm} />
      </div>
    </div >
  );
}

export default Patient;

