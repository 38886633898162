import React, { useRef, useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Collapse,
    Box,
    Typography,
    Paper,
    Container,
    TableSortLabel,
    TextField,
    Button,
    Dialog,
    DialogContent,
    FormControlLabel,
    Checkbox,
    CircularProgress,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import { KeyboardArrowDown, KeyboardArrowUp, ChevronLeft, ChevronRight, ZoomIn, ZoomOut, Replay } from '@mui/icons-material';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    LinearScale,
    PointElement,
    Tooltip,
    CategoryScale,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';


const API_PATIENTS_URL = 'https://api.emcardia.com/patients';
const API_ECG_URL = 'https://api.emcardia.com/ecg';
const API_KEY = 'QZtBbIjzTQ7zVc3gKQxYE9EOy3Z99PWE10lYtgY5';

const EcgPage = () => {

    const { t } = useTranslation();

    const [patients, setPatients] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('patientCode');
    const [filterDate, setFilterDate] = useState('');
    const [selectedEcg, setSelectedEcg] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [ecgData, setEcgData] = useState([]);
    const [filteredEcgData, setFilteredEcgData] = useState([]);

    const [loadingEcgId, setLoadingEcgId] = useState(null);


    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    const handleRowClick = (patientCode) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [patientCode]: !prevExpandedRows[patientCode],
        }));
    };

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleFilterDateChange = (event) => {
        setFilterDate(event.target.value);
    };

    useEffect(() => {
        const token = getCookie('jwt');
        axios
            .get(API_PATIENTS_URL, {
                headers: {
                    'x-api-key': API_KEY,
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((response) => {
                setPatients(response.data);
            })
            .catch(() => {
                alert('Failed to fetch patients.');
            });
    }, []);

    const sortAndFilterPatients = (patients) => {
        const filteredPatients = patients.filter((patient) =>
            filterDate ? patient.dateOfBirth.startsWith(filterDate) : true
        );

        return filteredPatients.sort((a, b) => {
            if (order === 'asc') return a[orderBy] < b[orderBy] ? -1 : 1;
            return a[orderBy] > b[orderBy] ? -1 : 1;
        });
    };

    const handleViewEcg = (patient, ecgId) => {
        const token = getCookie('jwt');
        setLoadingEcgId(ecgId);
        axios
            .get(`${API_ECG_URL}/${ecgId}?patientCode=${patient.patientCode}&accessCode=${patient.accessCode}`, {
                headers: {
                    'x-api-key': API_KEY,
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((response) => {
                setEcgData(response.data.voltageMeasurements);
                setFilteredEcgData(response.data.filteredVoltageMeasurements);
                setSelectedEcg(ecgId);
                setOpenModal(true);
                setLoadingEcgId(null);

            })
            .catch(() => {
                alert('Failed to fetch ECG details.');
                setLoadingEcgId(null);
            });
    };

    return (
        <Container maxWidth="lg" style={{ marginTop: '40px' }}>
            <TextField
                label={t('ecg.filterByDoB')}
                type="date"
                value={filterDate}
                onChange={handleFilterDateChange}
                InputLabelProps={{ shrink: true }}
                style={{ marginBottom: '16px', width: '100%' }}
            />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'patientCode'}
                                    direction={orderBy === 'patientCode' ? order : 'asc'}
                                    onClick={() => handleSort('patientCode')}
                                >
                                    {t('ecg.patientCode')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'accessCode'}
                                    direction={orderBy === 'accessCode' ? order : 'asc'}
                                    onClick={() => handleSort('accessCode')}
                                >
                                    {t('ecg.accessCode')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'dateOfBirth'}
                                    direction={orderBy === 'dateOfBirth' ? order : 'asc'}
                                    onClick={() => handleSort('dateOfBirth')}
                                >
                                    {t('ecg.dob')}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortAndFilterPatients(patients).map((patient) => (
                            <PatientRow
                                key={patient.patientCode}
                                patient={patient}
                                isExpanded={expandedRows[patient.patientCode]}
                                handleRowClick={handleRowClick}
                                handleViewEcg={handleViewEcg}
                                loadingEcgId={loadingEcgId}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal pour afficher l'ECG */}
            {selectedEcg && (
                <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="lg" fullWidth>
                    <DialogContent style={{ width: '100%' }}>
                        <Typography variant="h6" gutterBottom>
                            {t('ecg.details')} {selectedEcg}
                        </Typography>
                        <VoltageMeasurementsGraph voltageMeasurements={ecgData} filteredVoltageMeasurements={filteredEcgData} />
                    </DialogContent>
                </Dialog>
            )}
        </Container>
    );
};

const PatientRow = ({ patient, isExpanded, handleRowClick, handleViewEcg, loadingEcgId }) => {
    const [ecgData, setEcgData] = useState([]);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    useEffect(() => {
        const token = getCookie('jwt');
        if (isExpanded && ecgData.length === 0) {
            setLoading(true);
            axios
                .get(`${API_ECG_URL}?patientCode=${patient.patientCode}&accessCode=${patient.accessCode}`, {
                    headers: {
                        'x-api-key': API_KEY,
                        'Authorization': `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    setEcgData(response.data);
                    setLoading(false);
                })
                .catch(() => {
                    alert('Failed to fetch ECG data.');
                    setLoading(false);
                });
        }
    }, [isExpanded, patient.patientCode, patient.accessCode, ecgData.length]);

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton size="small" onClick={() => handleRowClick(patient.patientCode)}>
                        {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell>{patient.patientCode}</TableCell>
                <TableCell>{patient.accessCode}</TableCell>
                <TableCell>{patient.dateOfBirth}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom>
                                {t('ecg.list')}
                            </Typography>
                            {loading ? (
                                <Typography>{t('ecg.loading')}</Typography>
                            ) : ecgData.length > 0 ? (
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('ecg.startDate')}</TableCell>
                                            <TableCell>{t('ecg.endDate')}</TableCell>
                                            <TableCell>{t('ecg.device')}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ecgData.map((ecg) => {
                                            const isLoading = loadingEcgId === ecg.id;
                                            return (
                                                <TableRow key={ecg.id}>
                                                    <TableCell>{ecg.startDate}</TableCell>
                                                    <TableCell>{ecg.endDate}</TableCell>
                                                    <TableCell>{ecg.device}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleViewEcg(patient, ecg.id)}
                                                            disabled={isLoading} // Désactive le bouton pendant le chargement pour cette ligne
                                                            startIcon={isLoading ? <CircularProgress size={24} /> : null} // Affiche CircularProgress si c'est en cours de chargement
                                                        >
                                                            {isLoading ? t('ecg.loading') : t('ecg.view')} {/* Affiche le texte approprié */}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            ) : (
                                <Typography>{t('ecg.noECG')}</Typography>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};


// Enregistrement des composants et plugins Chart.js
ChartJS.register(
    LineElement,
    LinearScale,
    PointElement,
    Tooltip,
    CategoryScale,
    zoomPlugin,
    annotationPlugin
);

const VoltageMeasurementsGraph = ({ voltageMeasurements, filteredVoltageMeasurements }) => {
    const mainChartRef = useRef(null);
    const overviewChartRef = useRef(null);

    // État pour contrôler l'affichage de la version filtrée
    const [showFilteredData, setShowFilteredData] = useState(false);

    // Préparation des données
    const labels = voltageMeasurements.map((_, i) => (i / 512).toFixed(4));
    const dataPoints = showFilteredData
        ? filteredVoltageMeasurements.map((item) => item.voltage)
        : voltageMeasurements.map((item) => item.voltage);

    // Données pour le graphique principal
    const mainData = {
        labels: labels,
        datasets: [
            {
                label: 'Voltage',
                data: dataPoints,
                borderColor: 'rgba(75,192,192,1)',
                fill: false,
                stepped: false,
                tension: 0.4,
            },
        ],
    };

    // Données pour le graphique d'aperçu
    const overviewData = {
        labels: labels,
        datasets: [
            {
                label: 'Voltage Overview',
                data: dataPoints,
                borderColor: 'rgba(200,200,200,1)',
                fill: false,
                stepped: false,
                tension: 0.4,
            },
        ],
    };

    // Options communes
    const commonOptions = {
        responsive: true,
        elements: {
            point: {
                radius: 0,
            },
            line: {
                borderWidth: 1,
            },
        },
        scales: {
            x: {
                type: 'linear',
                min: 0,
                max: labels.length / 512,
                ticks: {
                    stepSize: 0.04,
                    autoSkip: false,
                    maxTicksLimit: 1000,
                    callback: function (value) {
                        const val = parseFloat(value);
                        const scaledVal = Math.round(val * 1000);
                        if (scaledVal % 200 === 0) {
                            return (scaledVal / 1000).toFixed(2) + 's';
                        } else {
                            return '';
                        }
                    },
                },
                grid: {
                    drawOnChartArea: true,
                    lineWidth: 0.5,
                    color: function (context) {
                        const val = parseFloat(context.tick.value);
                        const scaledVal = Math.round(val * 1000);
                        return scaledVal % 200 === 0 ? '#000' : '#ccc';
                    },
                },
            },
            y: {
                min: -0.0005, // Minimum -0.5 mV
                max: 0.001,    // Maximum 1 mV
                ticks: {
                    stepSize: 0.0001,
                    callback: function (value) {
                        return (value * 1000).toFixed(1) + ' mV';
                    },
                },
                grid: {
                    color: function (context) {
                        const val = context.tick.value * 1000;
                        return Math.abs(val % 0.5) < 0.0001 ? '#000' : '#ccc';
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    mode: 'x',
                },
            },
        },
    };

    const mainOptions = {
        ...commonOptions,
        scales: {
            ...commonOptions.scales,
            x: {
                ...commonOptions.scales.x,
                min: 0,
                max: 3, 
            },
        },
        plugins: {
            ...commonOptions.plugins,
            zoom: {
                ...commonOptions.plugins.zoom,
                pan: {
                    ...commonOptions.plugins.zoom.pan,
                    onPanComplete: function ({ chart }) {
                        updateOverview();
                    },
                },
                zoom: {
                    ...commonOptions.plugins.zoom.zoom,
                    onZoomComplete: function ({ chart }) {
                        updateOverview();
                    },
                },
            },
        },
    };

    // Options pour le graphique d'aperçu
    const overviewOptions = {
        ...commonOptions,
        scales: {
            ...commonOptions.scales,
            x: {
                ...commonOptions.scales.x,
                display: false,
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
            y: {
                ...commonOptions.scales.y,
                display: false,
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
        },
        plugins: {
            ...commonOptions.plugins,
            zoom: {
                pan: {
                    enabled: false,
                },
                zoom: {
                    enabled: false,
                },
            },
            annotation: {
                annotations: {
                    currentView: {
                        type: 'box',
                        xMin: 0,
                        xMax: 10,
                        yMin: -0.0005,
                        yMax: 0.001,
                        backgroundColor: 'rgba(0, 0, 255, 0.1)',
                        borderColor: 'blue',
                        borderWidth: 1,
                    },
                },
            },
        },
    };

    const updateOverview = () => {
        if (mainChartRef.current && overviewChartRef.current) {
            const mainChart = mainChartRef.current.chartInstance || mainChartRef.current;
            const overviewChart = overviewChartRef.current.chartInstance || overviewChartRef.current;

            const mainXScale = mainChart.scales.x;
            const min = mainXScale.min;
            const max = mainXScale.max;

            // Mise à jour de l'annotation dans le graphique d'aperçu
            overviewChart.options.plugins.annotation.annotations.currentView.xMin = min;
            overviewChart.options.plugins.annotation.annotations.currentView.xMax = max;

            overviewChart.update('none');
        }
    };

    const handlePanLeft = () => {
        if (mainChartRef.current) {
            const chart = mainChartRef.current.chartInstance || mainChartRef.current;
            chart.pan({ x: 200 }, undefined, 'default');
        }
    };

    const handlePanRight = () => {
        if (mainChartRef.current) {
            const chart = mainChartRef.current.chartInstance || mainChartRef.current;
            chart.pan({ x: -200 }, undefined, 'default');
        }
    };

    const handleZoomIn = () => {
        if (mainChartRef.current) {
            const chart = mainChartRef.current.chartInstance || mainChartRef.current;
            chart.zoom(1.2);
        }
    };

    const handleZoomOut = () => {
        if (mainChartRef.current) {
            const chart = mainChartRef.current.chartInstance || mainChartRef.current;
            chart.zoom(0.8);
        }
    };

    const handleResetZoom = () => {
        if (mainChartRef.current) {
            const chart = mainChartRef.current.chartInstance || mainChartRef.current;
            chart.resetZoom();
        }
    };


    useEffect(() => {
        updateOverview();
    }, [showFilteredData]); // Mise à jour lorsque la case à cocher change

    return (
        <Box display="flex" width="100%" height="500px">
            {/* Graphique principal sur 80% de la largeur */}
            <Box width="80%">
                <Paper elevation={3} style={{ padding: '16px', boxSizing: 'border-box', position: 'relative' }}>
                    <IconButton
                        onClick={handlePanLeft}
                        style={{ position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)', zIndex: 10 }}
                    >
                        <ChevronLeft />
                    </IconButton>
                    <Line ref={mainChartRef} data={mainData} options={mainOptions} />
                    <IconButton
                        onClick={handlePanRight}
                        style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)', zIndex: 10 }}
                    >
                        <ChevronRight />
                    </IconButton>
                </Paper>
            </Box>

            {/* Quicklook et case à cocher sur la partie droite */}
            <Box width="20%" display="flex" flexDirection="column" alignItems="center">
                {/* Quicklook */}
                <Box width="100%" padding={2}>
                    <Paper elevation={3} style={{ padding: '10px', height: '150px' }}>
                        <Line ref={overviewChartRef} data={overviewData} options={overviewOptions} />
                    </Paper>
                </Box>

                {/* Case à cocher pour afficher la version filtrée */}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showFilteredData}
                            onChange={(e) => setShowFilteredData(e.target.checked)}
                            color="primary"
                        />
                    }
                    label="Afficher la version filtrée"
                />

                {/* Boutons de contrôle sous la case à cocher */}
                <Box display="flex" justifyContent="center" marginTop={2}>
                    <IconButton onClick={handleZoomIn} color="primary" style={{ marginRight: 8 }}>
                        <ZoomIn />
                    </IconButton>
                    <IconButton onClick={handleZoomOut} color="secondary" style={{ marginRight: 8 }}>
                        <ZoomOut />
                    </IconButton>
                    <IconButton onClick={handleResetZoom} color="default" style={{ marginRight: 8 }}>
                        <Replay />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default EcgPage;
