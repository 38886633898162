import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import Dashboard from './Dashboard';
import Layout from './Layout';
import Patient from './Patient';
import Ecg from './Ecg';
import { jwtDecode } from 'jwt-decode';
import UserPool from './UserPool'; // Assurez-vous que ce fichier est bien configuré
import { CognitoUser } from 'amazon-cognito-identity-js';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Fonction pour obtenir un cookie spécifique
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      const cookieValue = parts.pop().split(';').shift();
      console.log(`Cookie "${name}" found with value:`, cookieValue);
      return cookieValue;
    }
    console.log(`Cookie "${name}" not found`);
    return null;
  };

  // Fonction pour renouveler le JWT en utilisant le Refresh Token
  const renewToken = () => {
    const refreshToken = getCookie('refreshToken');
    const cognitoUser = new CognitoUser({
      Username: jwtDecode(getCookie('jwt')).email,  // Utilise l'email du token pour créer l'objet user
      Pool: UserPool,
    });

    cognitoUser.refreshSession(refreshToken, (err, session) => {
      if (err) {
        console.error('Error refreshing token:', err);
        handleLogout();
      } else {
        const newIdToken = session.getIdToken().getJwtToken();
        console.log('New JWT:', newIdToken);
        document.cookie = `jwt=${newIdToken}; path=/; Secure; HttpOnly; SameSite=Strict`;
      }
    });
  };

  // Vérification de l'authentification via les cookies
  useEffect(() => {
    console.log('useEffect triggered to check authentication status');
    const token = getCookie('jwt'); // Récupère le JWT du cookie
    if (token) {
      try {
        const decoded = jwtDecode(token);
        console.log('Decoded JWT:', decoded);
        if (decoded.exp * 1000 > Date.now()) {
          console.log('Token is valid. User is authenticated.');
          setIsAuthenticated(true);
        } else {
          console.log('Token has expired. Attempting to renew...');
          renewToken();  // Renouvelle le token si expiré
        }
      } catch (err) {
        console.error('Error decoding token:', err);
        setIsAuthenticated(false);
      }
    } else {
      console.log('No token found. User is not authenticated.');
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  }, []);

  const handleLogout = () => {
    console.log('User logged out. Clearing cookies and setting isAuthenticated to false.');
    document.cookie = 'jwt=; Max-Age=0; path=/; Secure; HttpOnly; SameSite=Strict'; // Supprime le cookie JWT
    document.cookie = 'refreshToken=; Max-Age=0; path=/; Secure; HttpOnly; SameSite=Strict'; // Supprime le cookie Refresh Token
    setIsAuthenticated(false);
  };

  const handleLogin = (jwtToken) => {
    console.log('User logged in. Setting isAuthenticated to true.');
    setIsAuthenticated(true);
  };

  if (isLoading) {
    return <div>Loading...</div>; // Affiche un écran de chargement tant que l'état de l'authentification n'est pas déterminé
  }

  return (
    <Router>
        <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout}>
            <Routes>
                <Route path="/" element={<LoggedRoute name="Home" element={<Home />} isAuthenticated={isAuthenticated} />} />
                <Route
                    path="/login"
                    element={isAuthenticated ? <Navigate to="/dashboard" /> : <LoggedRoute name="Login" element={<Login onLogin={handleLogin} />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                    path="/dashboard"
                    element={isAuthenticated ? <LoggedRoute name="Dashboard" element={<Dashboard />} isAuthenticated={isAuthenticated} /> : <Navigate to="/login" />}
                />
                <Route
                    path="/patients"
                    element={isAuthenticated ? <LoggedRoute name="Patient" element={<Patient />} isAuthenticated={isAuthenticated} /> : <Navigate to="/login" />}
                />
                                <Route
                    path="/ecg"
                    element={isAuthenticated ? <LoggedRoute name="Ecg" element={<Ecg />} isAuthenticated={isAuthenticated} /> : <Navigate to="/login" />}
                />
                {/* Redirection catch-all */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Layout>
    </Router>
  );
}

// Composant pour logguer les informations de routage
function LoggedRoute({ name, element, isAuthenticated }) {
  const location = useLocation();
  console.log(`Rendering route: ${name}, current path: ${location.pathname} because isAuthenticated is equal to ${isAuthenticated}`);
  return element;
}

export default App;

