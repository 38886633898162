import React from 'react';

function Dashboard() {
  return (
      <div className="container my-5">
        <h1>Welcome to the Dashboard</h1>
        <p>This is a protected page only accessible to logged-in users.</p>
      </div>
  );
}

export default Dashboard;

